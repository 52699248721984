import React from "react"
import { graphql } from 'gatsby'

import Banner from '../components/banner'
import Layout from '../components/layout'
import SEO from "../components/seo"
import Hero from '../components/hero'
import Listing from '../components/listing'
import ScrollToTop from '../components/scroll'

import { prettyCategory } from '../utils/helpers'

/** @jsx jsx */
import { Container, jsx } from 'theme-ui'

const singleCanton = (props) => {
    const listings = props.data.allPrismicEntreprise.edges.map( ({node: listing}) => listing )
    const category = props.pageContext.category
    const canton = props.pageContext.cantonName
    var resultsNumber = listings.filter( listing => listing !== null ).length

    // console.log("LISTINGS", listings)

    return (
        <Layout>
            <SEO title={canton} description={`Tous nos établissements dans le canton ${canton}`} />

            <Container>
                <Hero 
                    resultsNumber={resultsNumber}
                    category={category}
                    canton={canton}
                />

                <div
                    sx={{
                        display:  "flex",
                        flexDirection: "column",
                        gap: 3,
                    }}
                >            
                    {listings.map( (listing, index) => {
                        return (
                            <React.Fragment key={listing.id}>
                                <Listing 
                                    uid={listing.uid}
                                    data={listing.data}
                                    prettyCategory={prettyCategory(category)}
                                />
                                {index > 3 && (index === 4 || index === 14 || index === 34) && <Banner position={index + 2} />}
                            </React.Fragment>
                        )
                    })}
                </div>
            </Container>

            <ScrollToTop showAfterHeight={800} />
        </Layout>
    )
}

export default singleCanton

export const singleCantonQuery = graphql`
    query singleCantonListings($category: String!, $canton: String!) {
        allPrismicEntreprise(
            filter: {data: {
                categories: {elemMatch: {category: {uid: {eq: $category}}}}, 
                locations: {elemMatch: {zones: {uid: {eq: $canton}}}},
                draft: {ne: true}
            }},
            sort: {order: DESC, fields: data___guest}
            ) {
            edges {
                node {
                    uid
                    ...ListingFragment
                }
            }
        }
    }
`